<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/topstory"> | Top Stories</router-link>
    <router-link to="/beststory"> | Best Stories</router-link>
    <router-link to="/newstory"> | New Stories</router-link>
    <router-link to="/ask"> | AsK </router-link>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
